import { USER as ActionTypes } from '../actionTypes';

const initialState = {

    user: {
        userId: 0,// int userId from User table
        id: '',
        firstName: '',
        lastName: '',
        middleName: '',
        initials: '',
        email: '',
        dobNowEmail: '',
        phoneNumber: '',
        homePhone: '',
        mobilePhone: '',
        isActive: true,
        hasAccess: false,
        isAdmin: false,
        isDirector: false,
        isInspector: false,
        isOfficeStaff: false,
        isWitness: false,
        canCreateCrossStateInspections: false,
        license: '',
        licenseExpiresOn: '',
        licenseTransferDate: '',
        salesforceContactId: '',
        salesforceUserId: '',
        avatar: '',
        offices: [],
        roles:[]
    },
    users: [],
    filter: {
        firstName: '',
        lastName: '',
        searchByOptions: [
            { value: 'all', label: 'All' },
            { value: 'id', label: 'ID' },
            { value: 'firstName', label: '<NAME>' },
            { value: 'lastName', label: '<NAME>' },
            { value: 'email', label: 'Email' },
            { value: 'phone', label: 'Phone' },
            { value: 'license', label: 'License' },
        ]
    }
};


export const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_USER:

            return {
                ...state,
                user: action.payload
            };
        case ActionTypes.CLEAR_USER:

            return {
                ...state,
                user: initialState
            };
        case ActionTypes.SET_USERS:

            return {
                ...state,
                users: action.payload
            };



        default:
            return state;
    };
};