export const PROFILE = {
	SET_PROFILE: 'SET_PROFILE',
	CLEAR_PROFILE: 'CLEAR_PROFILE',
};

export const APP_STATE = {
	API_DOWN: 'API_DOWN'
};

export const CUSTOMER = {
	SET_CUSTOMER: 'SET_CUSTOMER',
	CLEAR_CUSTOMER: 'CLEAR_CUSTOMER',
	GET_CUSTOMER: 'GET_CUSTOMER',
	SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
	CLEAR_CUSTOMER_LIST: 'CLEAR_CUSTOMER_LIST',
	SET_CUSTOMER_SEARCH_TEXT: 'SET_CUSTOMER_SEARCH_TEXT',
	VERIFY_CUSTOMER: 'VERIFY_CUSTOMER',
	SET_INSPECTION_LIST: 'SET_INSPECTION_LIST',
	SET_CUSTOMER_DD_NAME_LIST: 'SET_CUSTOMER_DD_NAME_LIST', // used for customer name dropdown
	SET_CUSTOMER_ANALYTICS_VIEW: 'SET_CUSTOMER_ANALYTICS_VIEW' // used for customer name dropdown
};

export const AGENT = {
	SET_AGENT: 'SET_AGENT',
	CLEAR_AGENT: 'CLEAR_AGENT',
	SET_AGENT_LIST: 'SET_AGENT_LIST',
	CLEAR_AGENT_LIST: 'CLEAR_AGENT_LIST',
	SET_AGENT_SEARCH_TEXT: 'SET_AGENT_SEARCH_TEXT',
	VERIFY_AGENT: 'VERIFY_AGENT',
};

export const BUILDING = {
	SET_BUILDING: 'SET_BUILDING',
	CLEAR_BUILDING: 'CLEAR_BUILDING',
	SET_BUILDING_LIST: 'SET_BUILDING_LIST',
	CLEAR_BUILDING_LIST: 'CLEAR_BUILDING_LIST',
	SET_BUILDING_SEARCH_TEXT: 'SET_BUILDING_SEARCH_TEXT',
	VERIFY_BUILDING: 'VERIFY_BUILDING',
	SET_BUILDING_DD_ADDRESS_LIST: 'SET_BUILDING_DD_ADDRESS_LIST', // used for dropdowns
	SET_BUILDING_DD_CORP_NAME_LIST: 'SET_BUILDING_DD_CORP_NAME_LIST' // used for dropdowns
};

export const DEVICE = {
	SET_DEVICE: 'SET_DEVICE',
	CLEAR_DEVICE: 'CLEAR_DEVICE',
	SET_DEVICE_LIST: 'SET_DEVICE_LIST',
	CLEAR_DEVICE_LIST: 'CLEAR_DEVICE_LIST',
	SET_DEVICE_SEARCH_TEXT: 'SET_DEVICE_SEARCH_TEXT',
	VERIFY_DEVICE: 'VERIFY_DEVICE',
	SET_DEVICE_DD_NAME_LIST: 'SET_DEVICE_DD_NAME_LIST'
};

export const ELEVATOR = {
	SET_ELEVATOR: 'SET_ELEVATOR',
	CLEAR_ELEVATOR: 'CLEAR_ELEVATOR',
	SET_ELEVATOR_LIST: 'SET_ELEVATOR_LIST',
	CLEAR_ELEVATOR_LIST: 'CLEAR_ELEVATOR_LIST',
	SET_ELEVATOR_SEARCH_TEXT: 'SET_ELEVATOR_SEARCH_TEXT',
	VERIFY_ELEVATOR: 'VERIFY_ELEVATOR',
	SET_ELEVATOR_DD_NAME_LIST: 'SET_ELEVATOR_DD_NAME_LIST'
};

export const ELEVATOR_CONTACT = {
	SET_ELEVATOR_CONTACT: 'SET_ELEVATOR_CONTACT',
	SET_ELEVATOR_CONTACT_LIST: 'SET_ELEVATOR_CONTACT_LIST',
	CLEAR_ELEVATOR_CONTACT_LIST: 'CLEAR_ELEVATOR_CONTACT_LIST',
	SET_ELEVATOR_CONTACT_SEARCH_TEXT: 'SET_ELEVATOR_CONTACT_SEARCH_TEXT',
	VERIFY_ELEVATOR_CONTACT: 'VERIFY_ELEVATOR_CONTACT',
};


export const INSPECTION_DASHBOARD = {
	SET_DASHBOARD_NAV: 'SET_DASHBOARD_NAV',
	GET_DASHBOARD_NAV: 'GET_DASHBOARD_NAV',
};

export const INSPECTION_SEARCH = {
	SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
	CLEAR_SEARCH_FILTERS: 'CLEAR_SEARCH_FILTERS',
	SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
};

export const INSPECTION = {
	SET_INSPECTION: 'SET_INSPECTION',
	CLEAR_INSPECTION: 'CLEAR_INSPECTION',
	SET_INSPECTION_BACK_ROUTE: 'SET_INSPECTION_BACK_ROUTE',
};

export const INSPECTION_LINES = {
	SET_LINE: 'SET_LINE',
	SET_DEFS_LIST: 'SET_DEFS_LIST',
	SET_LINE_EDIT: 'SET_LINE_EDIT'
};


export const INSPECTION_REPORT = {
	SET_REPORT_RESULTS: 'SET_REPORT_RESULTS',
	SET_REPORT_FILTERS: 'SET_REPORT_FILTERS',
	CLEAR_REPORT_FILTERS: 'CLEAR_REPORT_FILTERS'
};




export const SCHEDULER = {
	SET_YEAR: 'SET_YEAR',
	SET_INSPECTORS: 'SET_INSPECTORS',
	SET_WEEKLY_INSPECTIONS: 'SET_WEEKLY_INSPECTIONS',
	SET_WEEK_HEADER_ARRAY: 'SET_WEEK_HEADER_ARRAY',
	SET_SELECTED_DAY: 'SET_SELECTED_DAY',
	SET_SELECTED_INSPECTOR: 'SET_SELECTED_INSPECTOR',
	CLEAR_SELECTED_DAY: 'CLEAR_SELECTED_DAY',
	CLEAR_SELECTED_INSPECTOR: 'CLEAR_SELECTED_INSPECTOR',
	SET_PENDING_FILTER: 'SET_PENDING_FILTER',
	SET_PENDING_INSPECTIONS: 'SET_PENDING_INSPECTIONS',
	SET_SCHEDULE_NAV: 'SET_SCHEDULE_NAV',
	SET_FILTER_ARRAYS: 'SET_FILTER_ARRAYS',
	SET_MAP_CENTER: 'SET_MAP_CENTER',
	SET_MAP_TAB: 'SET_MAP_TAB',
	SET_SINGLE_INSPECTION: 'SET_SINGLE_INSPECTION',
	SET_SELECTED_INSP_ID: 'SET_SELECTED_INSP_ID',
	SET_SELECTED_INSP_LINE: 'SET_SELECTED_INSP_LINE',
	SET_INSP_LINE_MODAL: 'SET_INSP_LINE_MODAL',
	CLEAR_SELECTED_INSP_LINE: 'CLEAR_SELECTED_INSP_LINE',
	SET_SHOW_LOADER: 'SET_SHOW_LOADER',
};


export const BOROUGH = {
	SET_BOROUGH_DD_NAME_LIST: 'SET_BOROUGH_DD_NAME_LIST'
};

export const HOOD = {
	SET_HOOD_DD_NAME_LIST: 'SET_HOOD_DD_NAME_LIST'
};


export const INSPECTOR = {
	SET_ACTIVE_INSPECTORS: 'SET_ACTIVE_INSPECTORS',
	SET_ALL_INSPECTORS: 'SET_ALL_INSPECTORS'
};

export const INVOICE = {
	SET_BATCH: 'SET_BATCH',
};

export const USER = {
	SET_USERS: 'SET_USERS',
	SET_USER: 'SET_USER',
	CLEAR_USER: 'CLEAR_USER',
};

export const OFFICE = {
	SET_OFFICES: 'SET_OFFICES',
	SET_OFFICE: 'SET_OFFICE'
};


export const PROPOSAL = {
	SET_INIT: 'SET_INIT', // set original proposal list, customer grouped, agent grouped
	//SET_FILTERED_LIST: 'SET_FILTERED_LIST',
	SET_FILTERS: 'SET_FILTERS',
	CLEAR_FIlTERS: 'CLEAR_FIlTERS'
};