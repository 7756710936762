import { PROPOSAL as ActionTypes } from '../actionTypes';

const initialState = {

    originalProposalList: [],
    filteredProposalList: [],
    filter: {
        proposalStatus: {
            value: 99,
            label: 'All Proposal Statuses'
        },
        checkedInStatus: {
            value: 99,
            label: 'All Checked In Statuses'
        },
        customerSelected: {
            value: 0,
            label: 'AAAAA'
        },
        agentSelected: {
            value: 0,
            label: 'AAAA'
        },
    },
    groupedCustomerList: [],
    groupedAgentList: [],
};


export const proposalReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_INIT:
            return {
                ...state,
                originalProposalList: action.payload.proposals,
                filteredProposalList: action.payload.proposals,
                groupedCustomerList: action.payload.customers,
                groupedAgentList: action.payload.agentName,
                filter: initialState.filter
            };


        case ActionTypes.SET_FILTERS:
            return {
                ...state,
                filter: action.payload.filter,
                filteredProposalList: action.payload.filteredProposalList
            };



        case ActionTypes.CLEAR_FIlTERS:

            return {
                ...state,
                filter: initialState.filter,
                filteredProposalList: state.originalProposalList
            };


        default:
            return state;
    };
};